@font-face {
    font-family: "iconfont"; /* Project id 2736943 */
    src: url("//at.alicdn.com/t/font_2736943_43oc74kqur.woff2?t=1642412359784") format("woff2"),
        url("//at.alicdn.com/t/font_2736943_43oc74kqur.woff?t=1642412359784") format("woff"),
        url("//at.alicdn.com/t/font_2736943_43oc74kqur.ttf?t=1642412359784") format("truetype");
}

.iconfont {
    font-family: "iconfont" !important;
    font-size: 16px;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -webkit-text-stroke-width: 0.2px;
    -moz-osx-font-smoothing: grayscale;
}
